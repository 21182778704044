import * as LR from './ListRobots.styles'
import Toggle from "react-toggle";
import "react-toggle/style.css"
import Text from "../Text"; // for ES6 modules

export interface ListRobotsProps {
    data: any[];
}

export default function ListRobots({data}: ListRobotsProps) {
    return <>
        {!data || data.length == 0 ? <Text color={"white"}>
            Você não tem nenhum robô cadastrado para analisar jogos por você
        </Text> : <LR.Wrapper>
            {data.map((robot, index) => <LR.Robot>
                <LR.Icon>{robot.icon}</LR.Icon>
                <LR.Name>{robot.name}</LR.Name>
                <LR.Status>
                    <Toggle
                        id='biscuit-status'
                        defaultChecked={robot.is_active}
                        aria-labelledby='biscuit-label'/>
                </LR.Status>
            </LR.Robot> )}
        </LR.Wrapper>
        }
    </>
}