import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 15px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  height: 50px;
`

export const Title = styled.h1`
    font-size: 16px;
  
`