import styled from "styled-components";

export const TextRules = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding:10px 0;
`

export const ButtonWrapper = styled.div`

`