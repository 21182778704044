import React from "react";
import * as PCB from "./PlanCardBox.styles";
import Button from "../Button";
import Space from "../Space";

export interface PlanCardProps {
    title: string;
    promotion?: string;
    value: string;
    descount?: string;
    label: string;
    variant: "primary" | "secondary";
    color?: string;
    theme?: string;
    width?: string;
    height?: string;
    advantage?: string;
    peark?: string[];
    onClick?: () => void;
}

export default function PlanCardBox({
                                        title,
                                        promotion,
                                        value,
                                        descount,
                                        label,
                                        variant,
                                        color,
                                        theme,
                                        width,
                                        height,
                                        advantage,
                                        peark,
                                        onClick
                                    }: PlanCardProps) {
    return (
        <PCB.Wrapper theme={theme} width={width} height={height}>
            <PCB.Title>{title}</PCB.Title>
            <PCB.Promotion>{promotion}</PCB.Promotion>
            <PCB.Promotion>
                R$
                <PCB.PromotionValue>{value}</PCB.PromotionValue>
            </PCB.Promotion>
            <PCB.Promotion>{descount}</PCB.Promotion>
            <Button onClick={onClick} label={label} variant={variant} radius={"5px"}/>
            <PCB.PrincipalAdvantage>{advantage}</PCB.PrincipalAdvantage>
            <PCB.AdvantagesArea>
                {peark &&
                    peark.map((item, index) => (
                        <>
                            <PCB.Advantage>{item}</PCB.Advantage>
                            {index < peark.length - 1 && <PCB.Divider/>}
                        </>
                    ))}
            </PCB.AdvantagesArea>
        </PCB.Wrapper>
    );
}
