import Select from 'react-dropdown-select';
import {Simulate} from "react-dom/test-utils";
import load = Simulate.load;

export interface DropdownSelectProps {
    options: any[];
    onChange: any;
    values: any[];
    loading?: boolean;
    placeholder: string
    multi: boolean;
}

export default function DropdownSelect({placeholder, options, onChange, values, loading, multi}: DropdownSelectProps) {
    return <Select
        multi={multi}
        placeholder={placeholder}
        labelField={'name'}
        valueField={'id'}
        options={options}
        onChange={onChange}
        values={values}
        loading={loading}
        searchBy={'name'}
    />
}