import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const Robot = styled.div`
  display: flex;
  width: 100%;
`

export const Icon = styled.div`
  flex: 1;
`

export const Name = styled.div`
  color: white;
  flex: 5;
`

export const Status = styled.div`
  flex: 1;
`