import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 10px;
`

export const Label = styled.label`
  font-size: 16px;
  font-weight: bold;
`