import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 60px;
`


export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const Title = styled.h1`
  font-size: 20px;
`
export const Subtitle = styled.h2`
  font-weight: normal;
  font-size: 16px;
`

export const Plans = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`