import CardBox from "../CardBox";
import * as R from './Rules.styles'
import Button from "../Button";
import React, {useState} from "react";
import DropdownSelect from "../DropdownSelect";
import 'rc-slider/assets/index.css';
import Slider from "rc-slider";
import {MiniLabel} from "./Rules.styles";
export interface RulesProps {
    createRule: () => void;
}

export default function Rules({createRule}: RulesProps) {
    const [teamSelected, setTeamSelected] = useState<'qualquer' | 'ambos' | 'casa' | 'visitante'>('qualquer')
    const [typeSelected, setTypeSelected] = useState<'menor' | 'menor_igual' | 'igual' | 'maior_igual' | 'maior'>('igual')
    const [attributeSelected, setAttributeSelected] = useState<any>([])
    const [rangeValue, setRangeValue] = useState<any>(0)
    const [options, setOptions] = useState<any[]>([
        {
            id: 1,
            name: 'Chutes no total'
        },
        {
            id: 2,
            name: 'Escanteios'
        }])
    const handleTeamSelected = (team: 'qualquer' | 'ambos' | 'casa' | 'visitante') => {
        setTeamSelected(team)
    }

    const handleTypeSelected = (type: 'menor' | 'menor_igual' | 'igual' | 'maior_igual' | 'maior') => {
        setTypeSelected(type)
    }

    const getThemeByTeam = (team: 'qualquer' | 'ambos' | 'casa' | 'visitante') => {
        return teamSelected === team ? 'primary' : 'secondary'
    }

    const getThemeByType = (type: 'menor' | 'menor_igual' | 'igual' | 'maior_igual' | 'maior') => {
        return typeSelected === type ? 'primary' : 'secondary'
    }

    const handleOnChange = (value: any) => {
        setAttributeSelected(value);
    }

    return <CardBox variant={'primary'}
                    label={'+ Adicionar Regra'}
                    theme={'dotted'}
                    align={'left'}
                    centerText={<>
                        <R.Wrapper>
                            Selecione a equipe que deseja
                            <R.Choices>
                                <Button label={'Qualquer'}
                                        variant={getThemeByTeam('qualquer')}
                                        onClick={() => handleTeamSelected('qualquer')}
                                />
                                <Button label={'Ambos'}
                                        variant={getThemeByTeam('ambos')}
                                        onClick={() => handleTeamSelected('ambos')}
                                />
                                <Button label={'Time da casa'}
                                        variant={getThemeByTeam('casa')}
                                        onClick={() => handleTeamSelected('casa')}
                                />
                                <Button label={'Time visitante'}
                                        variant={getThemeByTeam('visitante')}
                                        onClick={() => handleTeamSelected('visitante')}
                                />
                            </R.Choices>
                            Selectione o atributo:
                            <R.Attribute>
                                <DropdownSelect options={options}
                                                onChange={handleOnChange}
                                                values={attributeSelected}
                                                multi={false}
                                                placeholder={'Selecione'}
                                />
                            </R.Attribute>
                            Tipo:
                            <R.Choices>
                                <Button label={'Menor'}
                                        variant={getThemeByType('menor')}
                                        onClick={() => handleTypeSelected('menor')}
                                />
                                <Button label={'Ambos'}
                                        variant={getThemeByType('menor_igual')}
                                        onClick={() => handleTypeSelected('menor_igual')}
                                />
                                <Button label={'Igual'}
                                        variant={getThemeByType('igual')}
                                        onClick={() => handleTypeSelected('igual')}
                                />
                                <Button label={'Maior Igual'}
                                        variant={getThemeByType('maior_igual')}
                                        onClick={() => handleTypeSelected('maior_igual')}
                                />
                                <Button label={'Maior'}
                                        variant={getThemeByType('maior')}
                                        onClick={() => handleTypeSelected('maior')}
                                />
                            </R.Choices>
                            Valor de {attributeSelected[0]?.name}:
                            <Slider
                                max={150}
                                min={1}
                                step={1}
                                defaultValue={0}
                                marks={{0: 0, 150: 150}}
                                onChange={setRangeValue}
                            />
                            <R.MiniLabel>Valor: {rangeValue}</R.MiniLabel>
                        </R.Wrapper>
                    </>}
    />
}