import styled from "styled-components";


export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Button = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`