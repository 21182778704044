import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`

export const Choices = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;

  @media (max-width: 450px) {
    flex-direction: column;
  }
`

export const Attribute = styled.div`
    width: 100%;
`

export const MiniLabel = styled.span<{
    textAlign?: string
}>`
  width: 100%;
  text-align: ${props => props.textAlign || 'center'};
  font-size: 12px;
`