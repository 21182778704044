import * as P from "./Plans.styles";
import PlanCardBox from "../../components/PlanCardBox";
import {useNavigate} from "react-router-dom";

export default function Plans() {
    const navigate = useNavigate();

    return <P.Wrapper>
        <P.HeaderWrapper>
            <P.Title>
                Conheça nossos planos VIP
            </P.Title>
            <P.Subtitle>
                *A renovação do plano é automática, para cancelar entre em contato.
            </P.Subtitle>
        </P.HeaderWrapper>
        <P.Plans>
            <PlanCardBox
                onClick={() => navigate('/payment')}
                title="Plano Mensal"
                variant="secondary"
                label="CRIAR PRIMEIRO ROBÔ"
                descount="R$149,00 de desconto"
                promotion="de R$ 299,00 por apenas"
                value="150,00"
                width="300px"
                advantage="3 meses pelo o preço de 2"
                peark={["Bot de escanteio 1° tempo", "Bot de mais/menos gols", " Custom Bot"]}
            />
        </P.Plans>
    </P.Wrapper>
}