import styled from "styled-components";
import { transparentize } from 'polished';



const COLORS = {
    red: '#F84735',
    primary: '#000000',
    secondary: '#ffffff',
    foreground: '#274060'
}

const THEME = {
    primary: {
        bg: '#000000',
        color: '#FFFFFF',
        onHover: `
        box-shadow: 0 3px 6px rgba(0,0,0,.2);
    `,
        disabled: {
            color: '#fff',
            bg: transparentize(0.44, COLORS.primary)
        }
    },
    secondary: {
        bg: '#ffffff',
        color: '#000000',
        onHover: `
        box-shadow: 0 3px 6px rgba(0,0,0,.2);
    `,
        disabled: {
            color: '#646464',
            bg: transparentize(0.44, COLORS.secondary)
        }
    }
}

export interface ButtonTheme{
    width?:  any;
    height?: any;
    variant: 'primary' | 'secondary'
    radius?: any;
}

export const Wrapper = styled.button<ButtonTheme>`
 padding: 8px 10px 6px;
  border: 1px solid ${p => THEME[p.variant].bg};
  border-radius: ${({radius}: ButtonTheme) => radius || '20px'};
  color: ${p => THEME[p.variant].color};
  background-color: ${p => THEME[p.variant].bg};
  width:${({width}: ButtonTheme) => width || 'auto'};
  height:${({height}: ButtonTheme) => height || 'auto'} ;
  &:hover,
  &:focus {
    ${p => THEME[p.variant].onHover};
  }

  &:disabled {
    background-color: ${p => THEME[p.variant].disabled.bg};
    color: ${p => THEME[p.variant].disabled.color};

    pointer-events: none;
    border-color: transparent;
  }
  cursor: pointer;
  
`

