import React from "react";
import {Route, BrowserRouter, Routes} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import RobotsTypes from "./pages/RobotsTypes";
import NewRobot from "./pages/NewRobot";
import Teste from "./pages/Teste/teste";
import Plans from "./pages/Plans";
import Payment from "./pages/Payment";

const RoutesConfig = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index={true} Component={Dashboard} path="/"/>
                <Route Component={RobotsTypes} path="/select-robots-types"/>
                <Route Component={NewRobot} path="/new"/>
                <Route Component={Plans} path="/plans"/>
                <Route Component={Payment} path="/payment"/>
                <Route Component={Teste} path="/teste"/>
            </Routes>
        </BrowserRouter>
    )
}

export default RoutesConfig;