import * as H from "../Header/Header.styles";
import LogoImg from "../../assets/imgs/bblogo.png";
import {LuLogOut} from "react-icons/lu";
import {useNavigate} from "react-router-dom";

export interface HeaderProps {
}


export default function Header() {

    const handleCreateNewRobot = () => {
        window.location.href = "/";
    }

    const handleLogout = () => {
        alert('logout')
    }


    return <H.Wrapper>
        <H.Logo src={LogoImg} alt="logo" onClick={handleCreateNewRobot}/>
        <H.Logout onClick={handleLogout}>
            <LuLogOut color={'white'}/>
        </H.Logout>
    </H.Wrapper>
}