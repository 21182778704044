import React, { useState } from "react";
import * as TFR from "./TextFormRules.styles";
import Text from "../Text";
import Button from "../Button";
import Rules from "../Rules";

export default function TextFormRules() {
  const [rouleOpened, setRouleOpened] = useState(false);
  const handleCreateRule = () => {
    setRouleOpened(!rouleOpened);
  };

  const handleOnCreateRule = () => {
    console.log("onCreateRule");
  };

  return (
    <TFR.TextRules>
      <Text>
        Você será alertado quando um jogo encaixar nas regras que você
        configurar
      </Text>
      <TFR.ButtonWrapper>
        <Button
          label={"+ CRIAR REGRA"}
          variant={"secondary"}
          onClick={handleCreateRule}
        />
        {rouleOpened && <Rules createRule={handleOnCreateRule} />}
      </TFR.ButtonWrapper>
    </TFR.TextRules>
  );
}
