import * as NR from "./NewRobot.styles";
import * as D from "../Dashboard/Dashboard.styles";
import CardBox from "../../components/CardBox";
import Text from "../../components/Text";
import Input from "../../components/Input";
import {useState} from "react";
import Modal from "react-modal";
import Space from "../../components/Space";
import Button from "../../components/Button";
import TextFormRules from "../../components/TextFormRules";
import AditionalsRules from "../../components/AditionalsRules";
import {ADDRCONFIG} from "dns";
import GamePeriod from "../../components/GamePeriod";
import GameSituation from "../../components/GameSituation";
import 'rc-slider/assets/index.css';
import League from "../../components/League";
import OddsMonitoring from "../../components/OddsMonitoring";

export default function NewRobot() {
    const [robotName, setRobotName] = useState<string>("");
    const [openVideoModal, setOpenVideoModal] = useState(false);
    const onOpenVideoModal = () => setOpenVideoModal(true);
    const onVideoCloseModal = () => setOpenVideoModal(false);

    return (
        <>
            <Modal
                onRequestClose={() => onVideoCloseModal()}
                style={{
                    overlay: {
                        backgroundColor: "rgba(0,0,0,0.5)",
                    },
                    content: {
                        margin: "auto",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "10px",
                        transition: "all 0.3s ease-in-out",
                    },
                }}
                isOpen={openVideoModal}
            >
                <iframe
                    width="100%"
                    height="100%"
                    src="https://www.youtube.com/embed/84cFFLlULCw"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    allowFullScreen
                ></iframe>
            </Modal>
            <NR.Wrapper>
                <D.Title>Configura um robô de acordo com as suas regras:</D.Title>
                <D.WrapperCardBox width={"100%"}>
                    <D.Boxes width={"100%"}>
                        <CardBox
                            width={"100%"}
                            title={
                                <Text color={"white"} size={24} align={"center"}>
                                    Criamos um tutorial <b>especial para você!</b>
                                </Text>
                            }
                            centerText={
                                <D.CenterWrapper>
                                    <Text color={"white"}>
                                        Assista para aproveitar o CUSTOM BOT da melhor forma!
                                    </Text>
                                </D.CenterWrapper>
                            }
                            label={"VER TUTORIAL"}
                            variant={"secondary"}
                            color={"black"}
                            onClick={onOpenVideoModal}
                        />
                    </D.Boxes>
                </D.WrapperCardBox>
                <NR.RobotWrapper>
                    <Input
                        label={"Dê um nome para o seu robô"}
                        placeholder={"Ex: Robô de tendência"}
                        value={robotName}
                        fontSize={"24px"}
                        onChange={(event) => setRobotName(event.target.value)}
                    />
                    <NR.TextRules>
                        <TextFormRules/>
                        <AditionalsRules/>
                        <GamePeriod/>
                        <GameSituation/>
                        <League/>
                        <OddsMonitoring/>
                    </NR.TextRules>
                    <NR.ActionWrapper>
                        <Button label={'+ CRIAR'} variant={'primary'}/>
                    </NR.ActionWrapper>
                </NR.RobotWrapper>

            </NR.Wrapper>
        </>
    );
}
