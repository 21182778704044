import React, { ReactHTMLElement } from "react";
import * as CB from "./CardBox.styles";
import Text, { TextProps } from "../Text";
import Button from "../Button";
import Space from "../Space";

export interface CardBoxProps extends TextProps {
  color?: string;
  theme?: string;
  width?: string;
  height?: string;
  title?: React.ReactNode;
  centerText?: React.ReactNode;
  variant: "primary" | "secondary";
  label: string;
  onClick?: () => void;
}

export default function CardBox({
  color,
  theme,
  width,
  height,
  title,
  centerText,
  variant,
  label,
  onClick,
}: CardBoxProps) {
  return (
    <CB.Wrapper color={color} theme={theme} width={width} height={height}>
      <CB.Title>{title}</CB.Title>
      <CB.CenterText>{centerText}</CB.CenterText>
      <CB.WrapperButton>
        <Button
          variant={variant}
          label={label}
          onClick={onClick}
        />
      </CB.WrapperButton>
    </CB.Wrapper>
  );
}
