import React from "react";
import * as AD from "./AditionalsRules.styles";
import Text from "../Text";

export default function AditionalsRules() {
  return (
    <AD.Wrapper>
      <AD.Title>Regras adicionadas ao robô:</AD.Title>
      <AD.Rules>Nenhuma regra adicionada ao robô</AD.Rules>
    </AD.Wrapper>
  );
}
