import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
export const Input = styled.input<{
    fontSize?: string;
}>`
  outline: none;
  text-decoration: none;
  border: none;
  border-bottom: 2px solid #000;
  padding: 4px;
  width: 300px;
  font-size: ${({ fontSize }) => fontSize || "16px"};
`