import styled from "styled-components";
import {CardBoxStyles} from "../CardBox/CardBox.styles"


export const Wrapper = styled.div<CardBoxStyles>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 20px;
  background:  ${({ color }: CardBoxStyles) => color || 'linear-gradient(-45deg, black, #222)'};
  border:2px ${({ theme }: CardBoxStyles) => theme || 'none'};
  border-radius: 10px;
  border-color: ${({ color }: CardBoxStyles) => color ? '#fff' : '#000'};
  width:${({width}: CardBoxStyles) => width || 'auto'};
  height:${({height}: CardBoxStyles) => height || 'auto'} ;
`

export const Title = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: #fff;
` 

export const Promotion = styled.span`
  font-size: 12px;
  color: #fff;
`

export const PromotionValue = styled.span`
  font-weight: bold;
  font-size: 48px;
  color: #fff;
`
export const PrincipalAdvantage = styled.span`
  border: 1px solid black;
  border-radius: 5px;
  width: 100%;
  margin: 0 10px;
  padding: 3px 0; 
  display: flex;
  justify-content: center;
  font-style: italic;
  color: #fff;
`

export const Advantage  = styled.span`
  color: #fff;
`
export const Divider = styled.span`
  width: 100%;
  border-bottom: 1px solid white;
  margin: 5px 0;
`

export const AdvantagesArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px; 
`