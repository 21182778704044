import { ButtonHTMLAttributes, ReactHTMLElement } from "react";
import * as B from "./Button.styles";

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  label: string;
  variant: "primary" | "secondary";
  onClick?: () => void;
  height?: string;
  width?: string;
  radius?: string;
}

export default function Button({
  label,
  variant,
  onClick,
  height,
  width,
  radius,
}: ButtonProps) {
  return (
    <B.Wrapper
      onClick={onClick}
      variant={variant}
      height={height}
      width={width}
      radius={radius}
    >
      {label}
    </B.Wrapper>
  );
}
