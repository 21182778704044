import valid from "card-validator";

interface FormValues {
    cardName: string;
    cardNumber: string;
    cardType: string;
    cardExpiration: string;
    cardSecurityCode: string;
    document: string;
}

interface FormErrors {
    show: boolean;
    variant: string;
    message: string;
    cname: boolean;
    cnumber: boolean;
    ctype: boolean;
    cexp: boolean;
    ccvv: boolean;
    document: boolean;
}

const validateInfo = (values: FormValues): FormErrors => {
    let errors: FormErrors = {
        show: true,
        variant: "danger",
        message: "An unknown error occurred. Please try again later",
        cname: false,
        cnumber: false,
        ctype: false,
        cexp: false,
        ccvv: false,
        document: false
    };

    let creditCardNumber = valid.number(values.cardNumber);
    let creditCardExpirationDate = valid.expirationDate(values.cardExpiration);
    let creditCardCvv = valid.cvv(values.cardSecurityCode);
    let creditCardCardholderName = valid.cardholderName(values.cardName);
    let documentValidation = '';

    console.log(creditCardNumber.isValid)

    if (values.cardSecurityCode === null || !values.cardSecurityCode.trim()) {
        errors.message = "Cartão de crédito não está completo";
    } else if (creditCardCvv.isValid) {
        errors.ccvv = true;
    } else {
        errors.message = "CVV não é válido";
    }

    if (values.cardExpiration === null || !values.cardExpiration.trim()) {
        errors.message = "Data do cartão de crédito não está completa";
    } else if (creditCardExpirationDate.isValid) {
        errors.cexp = true;
    } else {
        errors.message = "Data de expiração do cartão de crédito é inválida";
    }

    if (values.cardNumber === null || !values.cardNumber.trim()) {
        errors.message = "Número do cartão não está completo";
    } else if (creditCardNumber.isValid) {
        errors.cnumber = true;
    } else {
        errors.message = "Número do cartão é inválido";
    }

    if (values.cardName === null || !values.cardName.trim()) {
        errors.message = "Nome do titular do cartão não está completo";
    } else if (creditCardCardholderName.isValid) {
        errors.cname = true;
    } else {
        errors.message = "Nome do titular do cartão é inválido";
    }

    if (
        errors.cname &&
        errors.cnumber &&
        errors.cexp &&
        errors.ccvv
    ) {
        errors.variant = "success";
        errors.show = false;
        errors.message = "Cartão de crédito válido";
    }
    return errors;
};

export default validateInfo;
