import * as OM from './OddsMonitoring.styles'
import LeagueChecked from "../LeagueCheck";
import {useState} from "react";
import DropdownSelect from "../DropdownSelect";
import {MiniLabel} from "../Rules/Rules.styles";
import Button from "../Button";
import {ButtonWrapper} from "./OddsMonitoring.styles";

export default function OddsMonitoring() {
    const [gameStyles, setGameStyles] = useState<any[]>([
        {
            id: 1,
            name: "Somente jogos empatados",
        },
        {
            id: 2,
            name: "Somente jogos não empatados",
        },
        {
            id: 3,
            name: "Somente jogos com mercado de escanteios disponível",
        }
    ]);
    const [monitoring, setMonitoring] = useState<boolean>(false);

    const getThemeByMonitoring = (monitor: boolean) => {
        return monitor == monitoring ? 'primary' : 'secondary';
    }

    return <OM.Wrapper>
        <OM.Title>Monitoramento de odds</OM.Title>
        <OM.ButtonWrapper>
            <Button width={'100%'}
                    label={'Não monitorar'}
                    variant={getThemeByMonitoring(false)}
                    onClick={() => setMonitoring(false)}
            />
            <Button
                width={'100%'}
                label={'Monitorar odds'}
                variant={getThemeByMonitoring(true)}
                onClick={() => setMonitoring(true)}
            />
        </OM.ButtonWrapper>
        <div>
            <LeagueChecked items={gameStyles} useBorder={false}/>
        </div>
    </OM.Wrapper>
}