import React, { useState } from "react";
import * as GS from "./GameSitauation.styles";
import { Title } from "../AditionalsRules/AditionalsRules.styles";
import Button from "../Button";

export default function GameSituation() {
  const [selectedButton, setSelectedButton] = useState<any>(null);
  const handleButtonClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <GS.Wrapper>
      <Title>Qual situação o jogo deve estar ?</Title>
      <GS.Button>
        <Button
          label={"Favorito perdendo"}
          variant={selectedButton === 1 ? "primary" : "secondary"}
          onClick={() => handleButtonClick(1)}
          height={"50px"}
        />
        <Button
          label={"Favorito perdendo ou empatando"}
          variant={selectedButton === 2 ? "primary" : "secondary"}
          onClick={() => handleButtonClick(2)}
          height={"50px"}
        />
        <Button
          label={"Tanto faz"}
          variant={selectedButton === 3 ? "primary" : "secondary"}
          onClick={() => handleButtonClick(3)}
          height={"50px"}
        />
      </GS.Button>
    </GS.Wrapper>
  );
}
