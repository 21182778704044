import * as D from '../Dashboard/Dashboard.styles';
import CardBox from "../../components/CardBox";
import Text from "../../components/Text";
import {FaRobot} from "react-icons/fa";
import {PiStrategyDuotone} from "react-icons/pi";
import {useNavigate} from "react-router-dom";

export default function RobotsTypes() {
    const navigate = useNavigate();

    const handleCreateCustomBot = () => {
        navigate('/new');
    }

    return <>
        <D.Wrapper>
            <D.Title>Selecione o tipo de robô</D.Title>
            <D.WrapperCardBox maxWidth={'400px'}>
                <D.Boxes>
                    <CardBox
                        title={
                            <Text color={"black"} weight={600} size={24}>
                                Robô personaliazdo (Custom bot) <FaRobot/>
                            </Text>
                        }
                        centerText={<D.CenterWrapper>
                            <PiStrategyDuotone size={80}/>
                            <Text color={"black"}>
                                O Custom bot permite você criar infinitas possibilidades e padrões para achar as
                                entradas perfeitas.
                            </Text>
                        </D.CenterWrapper>
                        }
                        label={"CRIAR CUSTOM BOT"}
                        variant={"primary"}
                        color={"#ccc"}
                        onClick={handleCreateCustomBot}
                    />
                </D.Boxes>
            </D.WrapperCardBox>
        </D.Wrapper>
    </>
}