import React from "react";
import * as GP from "./GamePeriod.styles";
import { Title } from "../AditionalsRules/AditionalsRules.styles";
import Slider from "rc-slider";

export default function GamePeriod() {
  function gerarJSON(
    intervalo: number,
    maximo: number
  ): Record<number, number> {
    const json: Record<number, number> = {};

    for (let i = intervalo; i <= maximo; i += intervalo) {
      json[i] = i;
    }
    return json;
  }

  return (
    <GP.Wrapper>
      <Title>Periodo do jogo</Title>
      <Slider
        max={90}
        min={1}
        step={1}
        range
        defaultValue={[45, 70]}
        marks={gerarJSON(5, 90)}
      />
    </GP.Wrapper>
  );
}
