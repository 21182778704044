import styled from "styled-components";

export const Wrapper = styled.div`
  height: 64px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 32px;
`

export const Logo = styled.img`
    height: 64px;
    width: 64px;
  cursor: pointer;
`

export const Logout = styled.div`
  cursor: pointer;
`