import React from "react";
import * as LC from './LeagueCheck.styles'

export interface LeagueCheckProps extends React.HTMLProps<HTMLInputElement> {
    items: any[];
    useBorder?: boolean;
}

export default function LeagueChecked({items, onChange, useBorder}: LeagueCheckProps) {
    return <>
        {items ? items.map((item, index) => <LC.Wrapper useBorder={useBorder}>
            <LC.Input
                type="checkbox"
                id={item.name}
                name={item.name}
                value={item.name}
                checked={item.checked}
                onChange={onChange}
            />
            <LC.Label htmlFor={item.name}>{item.name}</LC.Label>
        </LC.Wrapper>) : ''} </>
}