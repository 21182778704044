import * as L from './League.styles'
import LeagueChecked from "../LeagueCheck";
import {useState} from "react";
import DropdownSelect from "../DropdownSelect";
import {MiniLabel} from "../Rules/Rules.styles";
export default function League() {
    const [leagues, setLeagues] = useState<any[]>([
        {
            id: 1,
            name: "Futebol Masculino",
        },
        {
            id: 2,
            name: "Futebol Feminino",
        },
        {
            id: 3,
            name: "Futebol de Base",
        }
    ]);
    const [selectedLeagues, setSelectedLeagues] = useState<any[]>([]);
    const [champions, setChampions] = useState<any[]>([
        {
            id: 1,
            name: "Brasileirão",
        },
        {
            id: 2,
            name: "Libertadores",
        },
        {
            id: 3,
            name: "Champions League",
        }
    ]);
    const [selectedChampions, setSelectedChampions] = useState<any[]>([]);

    return <L.Wrapper>
        <L.Label>Ligas</L.Label>
        <LeagueChecked items={leagues} useBorder={true}/>
        <DropdownSelect options={champions}
                        onChange={setSelectedChampions}
                        values={selectedChampions}
                        placeholder={'Selecione campeonatos especificos'}
                        multi={true}
        />
        <MiniLabel textAlign={'left'}>Deixa em branco para selecionar todas as ligas</MiniLabel>
    </L.Wrapper>
}