import styled from "styled-components";

export const Wrapper = styled.div<{
    useBorder?: boolean;
}>`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  border-radius: 20px;
  padding: ${({useBorder}) => useBorder ? '15px' : '0'};
  border: ${({useBorder}) => useBorder ? '2px solid #000' : 'none'};
  margin: 4px;
`

export const Input = styled.input`
  height: 16px;
  border: 1px solid #000;
  border-radius: 5px;
  padding: 0 10px;
  margin-right: 10px;
  font-size: 16px;
  outline: none;
`

export const Label = styled.label`
  font-size: 16px;
  margin-right: 10px;
  font-weight: bold;
`