import styled from 'styled-components';

export interface CardBoxStyles{
    color?: string;
    theme?: string;
    width?: string;
    height?: string;
}

export const Wrapper = styled.div<CardBoxStyles>`
  background-color: ${({color}: CardBoxStyles) => color || '#fff'};
  padding: 30px;
  border-radius: 10px;
  border: 2px ${({theme}: CardBoxStyles) => theme || 'none'};
  border-color: ${({color}: CardBoxStyles) => color ? '#fff' : '#000'};
  width: 100%;
  height: ${({height}: CardBoxStyles) => height || 'auto'};
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: flex-start;
`
export const Title = styled.div`

`
export const CenterText = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const WrapperButton = styled.div` 
  display: flex;
  justify-content: center;
` 
