import styled from "styled-components";

export const Logged = styled.span`
  float: right;
  margin-right: 32px;
`
export const Wrapper = styled.div`
  padding: 20px 32px;
`

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 500;
`

export const WrapperCardBox = styled.div<{
    maxWidth?: string;
    width?: string;
}>`
  width: ${({ width }) => width || 'auto'};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  max-width: ${({ maxWidth }) => maxWidth || 'auto'};
`
export const Boxes = styled.div<{
    width?: string;
}>`
  flex: 1;
  justify-content: center;
`

export const CenterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
`