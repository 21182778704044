import CardBox from "../../components/CardBox";
import Text from "../../components/Text";
import * as D from "./Dashboard.styles";
import {BsFillChatFill} from "react-icons/bs";
import {FaRobot} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import ListRobots from "../../components/ListRobots";
import {useState} from "react";

export default function Index() {
    const navigate = useNavigate();
    const [robots, setRobots] = useState([
        {
            id: 1,
            name: 'Robot 1',
            is_active: true,
            icon: '🤖'
        },
    ]);

    const handleCreateNewRobot = () => {
        navigate("/select-robots-types");
    }

    return (
        <>
            <D.Wrapper>
                <D.Title>Dashboard</D.Title>
                <D.WrapperCardBox>
                    <D.Boxes>
                        <CardBox
                            title={
                                <Text color={"white"} size={24}>
                                    Bots <FaRobot/>
                                </Text>
                            }
                            centerText={
                                <ListRobots data={robots}/>
                            }
                            label={robots && robots.length > 0 ? "CRIAR NOVO BOT" : "CRIAR PRIMEIRO ROBÔ"}
                            onClick={handleCreateNewRobot}
                            variant={"secondary"}
                            color={"black"}
                        />
                    </D.Boxes>
                    <D.Boxes>
                        <CardBox
                            title={
                                <Text size={24}>
                                    Chats <BsFillChatFill/>
                                </Text>
                            }
                            centerText={
                                <Text>
                                    Você não tem nenhum chat cadastrado para receber mensagens
                                </Text>
                            }
                            label={"CONECTAR AO TELEGRAM"}
                            variant={"primary"}
                            color={"#ccc"}
                        />
                    </D.Boxes>
                </D.WrapperCardBox>
            </D.Wrapper>
        </>
    );
}
