import * as I from './Input.styles';
import React from "react";

export interface InputProps {
  value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    type?: 'text' | 'number';
    label?: string;
    fontSize?: string;

}
export default function Input (props: InputProps){
  return <I.Wrapper>
    <span>{props.label}</span>
    <I.Input
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        type={props.type}
        fontSize={props.fontSize}
    />
  </I.Wrapper>
}