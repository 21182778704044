import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px 32px;
  max-width: 900px;
  width:100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const RobotWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background-color: #efefef;
  width: 100%;
  padding: 20px;
  border-radius: 8px;
`
export const TextRules = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
`

export const ActionWrapper = styled.div`
  margin-top: 20px;
`