import Button from "../../components/Button";
import {useState} from "react";
import Rules from "../../components/Rules";

export default function () {
    const [onCreateRule, setOnCreateRule] = useState(true);
    const [rules, setRules] = useState<any[]>([]);

    const handleCreateRule = () => {
        setOnCreateRule(!onCreateRule);
    }
    const handleOnCreateRule = () => {
        console.log('created')
    }

    return <>
        <Button label={'Criar Regra'} variant={'primary'} onClick={handleCreateRule}/>
        {onCreateRule && <Rules createRule={handleOnCreateRule}/>}
    </>
}