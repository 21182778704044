import { useState, ChangeEvent, FocusEvent, FormEvent } from 'react';
import validateInfo from './validateInfo';

interface FormValues {
    cardName: string;
    cardNumber: string;
    cardType: string;
    cardExpiration: string;
    cardSecurityCode: string;
    document: string;
    focus: string;
}

interface FormErrors {
    [key: string]: any;
}

interface useFormProps {
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handleFocus: (e: FocusEvent<HTMLInputElement>) => void;
    handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
    values: FormValues;
    errors: FormErrors;
}

const useForm = (): useFormProps => {
    const [values, setValues] = useState<FormValues>({
        cardName: '',
        cardNumber: '',
        cardType: '',
        cardExpiration: '',
        cardSecurityCode: '',
        document: '',
        focus: ''
    });

    const [errors, setErrors] = useState<any>({});

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        setValues({
            ...values,
            focus: e.target.name === 'cardSecurityCode' ? 'cvc' : e.target.name
        });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const validationErrors = validateInfo(values);
        setErrors(validationErrors);
    };

    return { handleChange, handleFocus, handleSubmit, values, errors };
};

export default useForm;
