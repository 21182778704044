import React, {useEffect} from "react";
import useForm from "./useForm";
import {Button, Form, Row, Col, Alert} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import styled from "styled-components";
import Cards, {Focused} from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import axios from 'axios';
import {toast} from "react-toastify";

const Container = styled.div`
  margin-top: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  justify-content: center;
  align-items: center;
`;

const FormDiv = styled.div`
  width: 100%;
  padding: 30px;
  border-radius: 10px;
  margin-bottom: 10%;
`;

const CreditCardWrapper = styled.div`
  margin-bottom: 10%;
`;

const AlertMessage = styled(Alert)`
  text-align: center;
  margin-top: 20px;
`;

const CreditCardForm: React.FC = () => {
    const {handleChange, handleFocus, handleSubmit, values, errors} = useForm();
    const [loading, setLoading] = React.useState(false);
    const doPayment = async () => {
        console.log(errors)
        setLoading(true)
        if (errors.show) {
            toast.warning('Preencha todos os campos corretamente', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                draggable: true

            })
        setLoading(false);
        } else {
            axios.post('https://paymentapi.conjosa.com.br/create-signature/', {
                "interval": "month",
                "description": "Plano mensal",
                "price": 150.00,
                "interval_count": 1,
                "quantity": 1,
                "card": {
                    "number": values.cardNumber,
                    "holder_name": values.cardName,
                    "exp_month": values.cardExpiration.split('/')[0],
                    "exp_year": values.cardExpiration.split('/')[1],
                    "cvv": values.cardSecurityCode,
                    "holder_document": values.document
                        .replaceAll('.', '')
                        .replaceAll('-', '')
                },
                "signature_id": "user@bbtips.com.br",
                "user_id": "user@bbtips.com.br",
                "user_name": "user@bbtips.com.br",
                "user_email": "user@bbtips.com.br",
                "user_document": "01234567890",
                "user_document_type": "CPF"
            }).then(response => {
                console.log(response)

                if(response.data?.status === 'active') {
                    toast.success("Pagamento realizado com sucesso!", {
                        position: "top-right",
                        autoClose: 0,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                } else {
                    toast.error("Ocorreu um erro ao realizar o pagamento", {
                        position: "top-right",
                        autoClose: 0,
                        hideProgressBar: false,
                        closeOnClick: true,
                    })
                }
            }).catch(err => {
                toast.error("Ocorreu um erro ao realizar o pagamento", {
                    position: "top-right",
                    autoClose: 0,
                    hideProgressBar: false,
                    closeOnClick: true,
                })
            }).finally(() => setLoading(false))

        }
    }

    useEffect(() => {
        if(JSON.stringify(errors) != '{}')
            doPayment();
    }, [errors])

    const formatCPF = (value: string) => {
        return value
            .replace(/\D/g, '')
            .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    };

    const handleCPFChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const formattedCPF = formatCPF(value);

        const event = {
            target: {
                name,
                value: formattedCPF,
            },
        } as React.ChangeEvent<HTMLInputElement>;

        handleChange(event);
    };

    return (
        <Container>
            <Box>
                <FormDiv>
                    <CreditCardWrapper>
                        <Cards
                            placeholders={{name: "NOME COMPLETO"}}
                            cvc={values.cardSecurityCode}
                            expiry={values.cardExpiration}
                            focused={values.focus as Focused}
                            name={values.cardName}
                            number={values.cardNumber}
                        />
                    </CreditCardWrapper>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group style={{marginBottom: '15px'}}>
                            <Form.Control
                                type="text"
                                id="cardName"
                                data-testid="cardName"
                                name="cardName"
                                placeholder="Nome do titular do cartão"
                                value={values?.cardName?.toUpperCase()}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                isValid={errors.cname}
                            />
                        </Form.Group>
                        <Form.Group style={{marginBottom: '15px'}}>
                            <Form.Control
                                type="number"
                                id="cardNumber"
                                data-testid="cardNumber"
                                name="cardNumber"
                                placeholder="Número do cartão"
                                value={values.cardNumber}
                                onChange={handleChange}
                                onFocus={handleFocus}
                                isValid={errors.cnumber}
                            />
                        </Form.Group>
                        <Row>
                            <Col>
                                <Form.Group style={{marginBottom: '15px'}}>
                                    <Form.Control
                                        type="number"
                                        id="cardSecurityCode"
                                        data-testid="cardSecurityCode"
                                        name="cardSecurityCode"
                                        placeholder="CVV"
                                        value={values.cardSecurityCode}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        isValid={errors.ccvv != undefined}
                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        id="cardExpiration"
                                        data-testid="cardExpiration"
                                        name="cardExpiration"
                                        placeholder="Data de expiração"
                                        value={values.cardExpiration}
                                        onChange={handleChange}
                                        onFocus={handleFocus}
                                        isValid={errors.cexp}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group style={{marginBottom: '15px'}}>
                                    <Form.Control
                                        type="text"
                                        name="document"
                                        id="document"
                                        data-testid="document"
                                        placeholder="CPF"
                                        value={values.document}
                                        onChange={handleCPFChange}
                                        onFocus={handleFocus}
                                        isValid={errors.ctype}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                        </Row>
                        <Button
                            size={"lg"}
                            style={{width: '100%'}}
                            data-testid="validateButton"
                            id="validateButton"
                            type="submit"
                            disabled={loading}
                        >
                            {!loading ? 'Assinar' : 'Pagando...'}
                        </Button>
                    </Form>
                </FormDiv>
            </Box>
        </Container>
    );
};

export default CreditCardForm;
