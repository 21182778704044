import React from 'react';
import logo from './logo.svg';
import './App.css';
import Header from "./components/Header";
import Routes from "./routes";
import * as D from "./pages/Dashboard/Dashboard.styles";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function App() {
    return (
        <>
            <ToastContainer />
            <header className="App-header">
                <Header/>
            </header>
            <D.Logged>
                logado com: <b>user@bbtips.com.br</b>
            </D.Logged>
            <Routes/>
        </>
    );
}

export default App;
